import { Button } from '../../../ui/buttons/button/button.component';
import cl from './action-button.module.scss';

interface ActionButtonProps {
	isPastEvent: boolean;
	onClick: () => void;
	text: string;
	disabled: boolean;
}

const ActionButton = ({
	isPastEvent,
	onClick,
	text,
	disabled,
}: ActionButtonProps) => (
	<Button
		className={cl.about__btn}
		theme={isPastEvent ? 'outline' : 'rainbow'}
		onClick={onClick}
		disabled={disabled}
	>
		{text}
	</Button>
);

export default ActionButton;
