interface ResponsiveWrapperProps {
	isMobile: boolean;
	children: React.ReactNode;
	wrapperClass?: string;
}

const ResponsiveWrapper = ({
	isMobile,
	children,
	wrapperClass = '',
}: ResponsiveWrapperProps) => {
	return isMobile ? (
		<div className={wrapperClass}>{children}</div>
	) : (
		<>{children}</>
	);
};

export default ResponsiveWrapper;
