import { useMemo } from 'react';
import Stepper from '../../../ui/stepper/stepper.component';
import { useAuth } from '../../../../application/application.auth.context';
import cl from './event-price-info.module.scss';

interface EventPriceInfoProps {
	isPrice: boolean;
	price: string;
	usersCount: number;
	maxUsers: number;
	currency: string;
	setCountTickets: (count: number) => void;
}

const EventPriceInfo = ({
	isPrice,
	price,
	usersCount,
	maxUsers,
	currency,
	setCountTickets,
}: EventPriceInfoProps) => {
	const { isAuth } = useAuth();

	const maxStepper = useMemo(() => {
		return maxUsers - usersCount;
	}, [usersCount, maxUsers]);

	return (
		<div className={cl.eventInfoWrapper}>
			{maxStepper > 0 && isAuth && (
				<div className={cl.eventStepperWrapper}>
					<Stepper min={1} max={maxStepper} onChange={setCountTickets} />
				</div>
			)}
			<div className={cl.event__info__price}>
				{isPrice && (
					<div className={cl.price}>
						{currency} <span>{price}</span>
					</div>
				)}
				<div className={cl.count}>
					<img src="/image/count.svg" alt="count-people" />
					{usersCount}
					<span>/</span>
					{maxUsers}
				</div>
			</div>
		</div>
	);
};
export default EventPriceInfo;
