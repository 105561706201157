import { IGenreData } from '../genre';
import { MasterListData } from '../master';

export enum Schedule {
	MEDITATION = 'MEDITATION',
	EVENT = 'EVENT',
	ALL = 'ALL',
}

export interface IScheduleFilters {
	type?: Schedule | null;
	date?: string | null;
	masters?: string | null;
	genre?: string | null;
	event_location?: string | null;
	start_date?: string | null;
	end_date?: string | null;
	limit?: number | null;
	offset?: number | null;
}

export type ScheduleFiltersKeys = keyof IScheduleFilters;

export interface IScheduleItemData {
	id: number;
	title: string;
	event_location: {
		pk: number;
		address: string;
		title: string;
		map_link?: string;
	};
	date: string;
	time_finish: string;
	is_active: boolean;
	type: Schedule;
	type_human: string;
	masters: MasterListData;
	genre: IGenreData;
	image?: string;
	description: string;
	max_users: number;
	bookings_count: number;
	price: string;
	user_bookings: IUserBookings[];
	user_bookings_count: number;
}

export interface IScheduleBooking {
	schedule: number,
	count: number,
}

interface IUserBookings {
	status: 'BOOK';
	status_text: string;
	count: number;
}

export interface IScheduleParticipate {
	already: boolean;
	ok: boolean;
	payment_link: string;
}

export type ScheduleListData = IScheduleItemData[];
