import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cl from './toggle-description.module.scss';

interface ToggleDescriptionProps {
	description: string;
}

const ToggleDescription = ({ description }: ToggleDescriptionProps) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [showFullHeight, setShowFullHeight] = useState(false);
	const [isClamped, setIsClamped] = useState(false);
	const [maxHeight, setMaxHeight] = useState([51, 200]);
	const descRef = useRef<HTMLParagraphElement>(null);

	const toggleDescription = () => {
		if (isOpen) {
			setShowFullHeight(false);

			setTimeout(() => {
				setIsOpen(false);
			}, 500);
		} else {
			setIsOpen(true);
			setTimeout(() => setShowFullHeight(true), 0);
		}
	};

	useLayoutEffect(() => {
		const checkClamping = () => {
			const el = descRef.current;

			if (el) {
				const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight);
				const clampHeight = lineHeight * 3;

				setMaxHeight([clampHeight, el.scrollHeight]);

				if (el.scrollHeight > clampHeight) {
					setIsClamped(true);
				}
			}
		};

		checkClamping();

		window.addEventListener('resize', checkClamping);
		return () => window.removeEventListener('resize', checkClamping);
	}, [description]);

	const formattedDescription = useMemo(
		() => description.replace(/\r?\n/g, '<br/>').trim(),
		[description],
	);

	const showLabel = useMemo(() => t('label.show'), []);
	const hideLabel = useMemo(() => t('label.hide'), []);

	const styleDescriptions = useMemo(() => {
		return {
			maxHeight: showFullHeight ? maxHeight[1] : maxHeight[0],
			minHeight: showFullHeight ? maxHeight[1] : maxHeight[0],
		}
	}, [showFullHeight, maxHeight])

	return (
		<>
			<p
				ref={descRef}
				style={styleDescriptions}
				className={`${cl.description} ${isOpen ? cl.open : ''}`}
				dangerouslySetInnerHTML={{ __html: formattedDescription }}
			></p>

			{isClamped && (
				<button onClick={toggleDescription} className={cl.button}>
					{isOpen ? hideLabel : showLabel}
				</button>
			)}
		</>
	);
};

export default ToggleDescription;
