import { useTranslation } from 'react-i18next';
import { Button } from '../../ui/buttons/button/button.component';
import Stepper from '../../ui/stepper/stepper.component';
import cl from './ticket-modal-cancellation-content.module.scss';

interface TicketModalCancellationContentProps {
	handleUnsubscribeTickets: () => void;
	purchasedCount?: number;
	handleUpdateCount: (value: number) => void;
}

const TicketModalCancellationContent = ({
	handleUnsubscribeTickets,
	purchasedCount,
	handleUpdateCount,
}: TicketModalCancellationContentProps) => {
	const { t } = useTranslation();

	return (
		<div className={cl.contentWrap}>
			<div className={cl.headingWrap}>
				<h2 className={cl.heading}>{t('modals.unsubscribe.heading')}</h2>
				<p className={cl.description}>{t('modals.unsubscribe.description')}</p>
			</div>

			<Stepper
				type={'doubleTickets'}
				min={1}
				max={purchasedCount}
				onChange={handleUpdateCount}
			/>

			<Button className={cl.button} theme="outline" onClick={handleUnsubscribeTickets}>
				{t('modals.unsubscribe.button')}
			</Button>
		</div>
	);
};

export default TicketModalCancellationContent;
