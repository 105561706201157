import cl from './count-tickets.module.scss';

interface ICountTicketsProps {
	countTickets: number;
}

const CountTickets = ({ countTickets }: ICountTicketsProps) => {
	return (
		<div className={cl.countTickets}>
			<img src="/image/ticket.svg" alt="" />
			<span className={cl.count}>{countTickets}</span>
		</div>
	);
};

export default CountTickets;
