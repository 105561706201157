import { parse, startOfMonth, subDays } from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import { useTranslation } from 'react-i18next';
import { IScheduleItemData } from '../../../@types';
import {
	CardEvent,
	CardEventEmpty,
	CardEventLoader,
} from '../../card/card-event/card-event.component';
import { SCHEDULE_GRID_LOADER } from './shedule-grid.const';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { convertDotDate, dateFnsParse } from '../../../utils/date.utils';
import cl from './schedule-grid.module.scss';

export const ScheduleGridList = ({
	list,
	date,
	onClickEvent,
	onClickSpeaker,
}: {
	onClickEvent: (
		e: React.MouseEvent<HTMLDivElement>,
		data?: string | number,
	) => void;
	date?: [Date | null, Date | null];
	onClickSpeaker: (e: React.MouseEvent, data?: string | number) => void;
	list: {
		date: string;
		items: IScheduleItemData[];
	}[];
}) => {
	const { i18n } = useTranslation();
	const { isMobile } = useWindowSize(1280);
	const locale = i18n.language === 'ru' ? ru : undefined;
	const currentDate: Date = new Date();
	const firstDayOfMonth: Date = startOfMonth(currentDate);

	return (
		<ul className={cl.scheduleGrid} key="desktop">
			{list.map((item, index) => {
				const { day, month, week } = dateFnsParse(
					convertDotDate(item.date),
					i18n.language,
					{
						week_format: 'EEEE',
					},
				);

				let shouldRenderEvents = false;
				const itemDate = parse(item.date, 'dd.MM.yyyy', new Date(), {
					locale: ru,
				});

				if (!isMobile) {
					if (date && date[0] && item.date) {
						shouldRenderEvents = itemDate < date[0];
					} else if (!date || (date[0] === null && date[1] === null)) {
						shouldRenderEvents = itemDate < subDays(new Date(), 1);
					}
				}

				return (
					<li key={item.date}>
						<div className={cl.scheduleGridItem}>
							<div className={cl.header}>
								{index <= 6 && !isMobile && (
									<div className={cl.week}>{week}</div>
								)}
								{isMobile && <div className={cl.week}>{week}</div>}
								{!shouldRenderEvents && (
									<div className={cl.date}>
										{day} {month}
									</div>
								)}
							</div>

							<ul className={cl.events}>
								{!shouldRenderEvents ? (
									item.items.length > 0 ? (
										item.items.map((event, index) => (
											<li
												className={cl.events__item}
												key={index + '-' + event.id}
											>
												<CardEvent
													onClick={onClickEvent}
													onClickSpeaker={onClickSpeaker}
													data={event}
												/>
											</li>
										))
									) : (
										<li className={cl.events__item} key={'empty-' + item.date}>
											<CardEventEmpty />
										</li>
									)
								) : (
									''
								)}
							</ul>
						</div>
					</li>
				);
			})}
		</ul>
	);
};

export const ScheduleGridListLoader = () => {
	const { width = window.innerWidth } = useWindowSize();
	const isMobile = width < 996;

	return (
		<ul className={cl.scheduleGrid} key="desktop-loader">
			{SCHEDULE_GRID_LOADER.map((item, index) => {
				return (
					<li key={item.date}>
						<div className={cl.scheduleGridItem}>
							{!isMobile && (
								<div className={cl.header}>
									<div className={'skeleton ' + cl.date__loader}></div>
									<div className={'skeleton ' + cl.week__loader}></div>
								</div>
							)}

							<ul className={cl.events}>
								{item.items.map((event, index) => (
									<li
										className={cl.events__item}
										key={index + '-' + event + '-loader'}
									>
										<CardEventLoader />
									</li>
								))}
							</ul>
						</div>
					</li>
				);
			})}
		</ul>
	);
};
