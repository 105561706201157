import { format, parse } from 'date-fns';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICardRecordProps } from './card-record.model';
import ResponsiveWrapper from '../../responsive-wrapper/responsive-wrapper.component';
import { Button } from '../../ui/buttons/button/button.component';
import CountMember from './common/count-member.component';
import CountTickets from './common/count-tickets.component';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { dateFnsParse, reverseDate } from '../../../utils/date.utils';
import cl from './card-record.module.scss';

export const CardRecord = ({
	data,
	status,
	className = '',
	onClick,
}: ICardRecordProps) => {
	const { i18n } = useTranslation();
	const { isMobile } = useWindowSize(830);
	const isPastEvent =
		parse(data.date, 'dd.MM.yyyy HH:mm:ss', new Date()) < new Date();
	const { week, day, month } = dateFnsParse(
		reverseDate(data.date),
		i18n.language,
	);

	const handleClickCard = useCallback(() => {
		onClick('clickCard', data);
	}, [data, onClick]);

	const handleChangeStatus = useCallback(
		(event: React.MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			onClick('changeStatus', data);
		},
		[data, onClick],
	);

	const translateStatus = (status: string) => {
		return t(`profile.events.${status}`);
	};

	const generateDuration = (dateStart: string, timeEnd: string) => {
		const start = parse(dateStart, 'dd.MM.yyyy HH:mm:ss', new Date());
		const end = parse(timeEnd, 'HH:mm:ss', new Date());
		return format(start, 'HH:mm') + ' - ' + format(end, 'HH:mm');
	};

	const cardClasses = [
		cl.card,
		className,
		cl[data.status],
		cl[status],
		cl[i18n.language],
	]
		.filter(Boolean)
		.join(' ');

	const generateTextButton = useMemo(() => {
		if (
			data.status === 'success' ||
			data.status === 'cancelled' ||
			data.status === 'pay_incomplete'
		) {
			return t('profile.events.button.order');
		}
		return t('profile.events.button.cancel');
	}, [data.status]);

	return (
		<div className={cardClasses} onClick={handleClickCard}>
			<div className={cl.row}>
				<div className={cl.col}>
					<div className={cl.card__title_and_address_wrap}>
						<div className={cl.card__title}>{data.title}</div>

						{data.event_location && (
							<div className={cl.card__address}>
								{data.event_location.address}
							</div>
						)}
					</div>
				</div>

				<ResponsiveWrapper isMobile={isMobile} wrapperClass={cl.columnReverse}>
					<div className={`${cl.col} ${cl.col_two}`}>
						<div className={cl.card__date_and_duration_wrap}>
							<div className={cl.card__date}>
								{week}, {day} {month}
							</div>
							<div className={cl.card__time}>
								{generateDuration(data.date, data.time_finish)}
							</div>
						</div>
					</div>

					<ResponsiveWrapper
						isMobile={isMobile}
						wrapperClass={cl.columnsWrapperMobile}
					>
						<div className={cl.col}>
							<CountMember
								currentMember={data.bookings_count}
								maxMember={data.max_users}
							/>
						</div>

						<div className={cl.col}>
							<CountTickets countTickets={data.user_bookings_count} />
						</div>
					</ResponsiveWrapper>
				</ResponsiveWrapper>

				<ResponsiveWrapper
					isMobile={isMobile}
					wrapperClass={cl.mobileButtonsWrapper}
				>
					<div className={cl.col}>
						<div className={cl.status_wrap}>
							<div className={`${cl.card__status} ${cl[data.status] || ''}`}>
								{translateStatus(data.status)}
							</div>
						</div>
					</div>

					<div
						className={`${cl.col} ${data.status === 'completed' ? cl.col_flex_0 : ''} ${cl.col_last}`}
					>
						{!isPastEvent &&
							(data.status === 'wait' || data.status === 'pay_incomplete') && (
								<Button
									className={`${cl.actionButton} ${cl[data.status]}`}
									theme="white-outline"
									onClick={handleChangeStatus}
								>
									{generateTextButton}
								</Button>
							)}
					</div>
				</ResponsiveWrapper>
			</div>
		</div>
	);
};
