import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IMasterData, IScheduleItemData, RoutsPath } from '../../@types';
import { AsideNavigation } from '../../components/aside/aside-profile/aside-profile.component';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import ModalEvent from '../../components/modals/modal-event/modal-event.component';
import ModalTicketCancellation from '../../components/modals/modal-ticket-cancellation/modal-ticket-cancellation.component';
import ModalUser from '../../components/modals/modal-user/modal-user.component';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { ButtonGroup } from '../../components/ui/button-group/button-group.component';
import { CardRecord } from 'src/components/card/card-record/card-record.component';
import { apiServices } from '../../services/api';
import cl from './index.module.scss';

type Record = 'first' | 'last';

const useRecords = ({
	limit = 10,
	offset = 0,
	isActive = true,
}: {
	limit: number;
	offset: number;
	isActive: boolean;
}) => {
	return useQuery({
		queryKey: [limit, offset, isActive, 'orders'],
		queryFn: async () => {
			return await apiServices.getRecords({ limit, offset, isActive });
		},
	});
};

const useGetRecord = (id: string) => {
	return useQuery({
		queryKey: [id, 'getRecord'],
		queryFn: async () => {
			return await apiServices.getRecord(id);
		},
		enabled: !!id,
	});
};

const ProfileRecordsPage = () => {
	const [record, setRecord] = useState<Record>('first');
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { status } = useParams<{ status: 'active' | 'completed' }>();
	const [recordId, setRecordId] = useState<string | null>(null);
	const [recordData, setRecordData] = useState<IScheduleItemData | null>(null);
	const [masters, setMastersData] = useState<IMasterData | null>(null);
	const [isOpenTicketCancellationModal, setIsOpenTicketCancellationModal] =
		useState(false);
	const [dataRecordUnsubscribe, setDataRecordUnsubscribe] = useState<IScheduleItemData | null>(null);

	const { data: fetchedRecordData } = useGetRecord(recordId as string);

	const meta = {
		title: t('helmet.personalAccount.orders.title'),
		description: t('helmet.personalAccount.orders.description'),
	};

	useEffect(() => {
		if (fetchedRecordData) {
			setRecordData(fetchedRecordData);
		}
	}, [fetchedRecordData]);

	const onChangeRecord = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.currentTarget;
		const data = target.dataset.value || 'first';
		setRecord(data as Record);
		navigate(`/profile/events/${record === 'last' ? 'active' : 'completed'}`);
	};

	useEffect(() => {
		if (status === 'completed') {
			setRecord('last');
		} else if (status === 'active') {
			setRecord('first');
		} else {
			navigate('/404');
		}
	}, [status]);

	const { data, isLoading } = useRecords({
		limit: 10,
		offset: 0,
		isActive: record === 'first',
	});

	const handleClickEvent = (event: 'changeStatus' | 'clickCard', card: any) => {
		if (event === 'changeStatus') {
			if (card.status === 'wait') {
				setIsOpenTicketCancellationModal(true);
				setDataRecordUnsubscribe(card);
			} else {
				if (card.payment_links.length > 0) {
					window.location.href = card.payment_links[0];
				}
			}
		} else {
			setRecordId(card.id);
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	function handleEventUpdate() {
		queryClient.invalidateQueries({
			queryKey: [10, 0, record === 'first', 'orders'], // Используйте тот же ключ, что и в useRecords
		});
	}

	const handleClose = () => {
		setRecordId(null);
	};

	const handleCloseTicketCancellationModal = () => {
		setIsOpenTicketCancellationModal(false);
	};

	const handleUpdateRecords = () => {
		handleEventUpdate();
	};

	const handleSpeakerClick = (user: IMasterData | null) => {
		setRecordData(null);
		setMastersData(user);
	};

	return (
		<PageLayout positionHeader="fixed" meta={meta}>
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs
						items={[
							{
								name: t('page.profile'),
								translate: true,
								href: RoutsPath.profile,
							},
							{ name: 'page.profile.events', translate: true },
						]}
					/>
					<h1 className="title-section-small padding-bottom_40">
						{t('page.profile.events')}
					</h1>
					<ContentLayout aside={<AsideNavigation />} small mobileTabs>
						<>
							<div className={cl.tab}>
								<ButtonGroup
									active={record}
									classes={{
										button: cl.tab__item,
										root: cl.tab__item,
									}}
									buttons={[
										{
											label: t('filter.active'),
											onClick: onChangeRecord,
											data: 'first',
										},
										{
											label: t('filter.closed'),
											onClick: onChangeRecord,
											data: 'last',
										},
									]}
								/>
							</div>

							{!isLoading && data && data.results.length === 0 && (
								<div className={cl.list__empty}>
									{t('profile.events.empty')}
								</div>
							)}

							{isLoading && (
								<ul className={cl.list}>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
								</ul>
							)}

							{data && data.results.length > 0 && !isLoading && (
								<ul className={cl.list}>
									{data.results.map((item) => (
										<li className={cl.list__item} key={item.id}>
											<CardRecord
												data={item}
												status={status || 'completed'}
												onClick={(event, data) => handleClickEvent(event, data)}
											></CardRecord>
										</li>
									))}
								</ul>
							)}
						</>
					</ContentLayout>
				</div>

				<Suspense fallback={<div />}>
					<ModalEvent
						event={recordData ?? null}
						setEvent={setRecordData}
						onUpdateEvent={handleEventUpdate}
						onClickSpeaker={handleSpeakerClick}
						handleClose={handleClose}
					/>
				</Suspense>

				<Suspense fallback={<div />}>
					<ModalUser data={masters} setData={setMastersData} />
				</Suspense>

				<Suspense fallback={<div />}>
					<ModalTicketCancellation
						dataRecord={dataRecordUnsubscribe}
						isOpen={isOpenTicketCancellationModal}
						updateRecords={handleUpdateRecords}
						closeModal={handleCloseTicketCancellationModal}
					/>
				</Suspense>
			</div>
		</PageLayout>
	);
};

export default ProfileRecordsPage;
