import React, { useState } from 'react';
import cl from './stepper.module.scss';

interface StepperProps {
	min?: number;
	max?: number;
	step?: number;
	value?: number;
	onChange?: (value: number) => void;
	type?: 'default' | 'doubleTickets';
}

const Stepper: React.FC<StepperProps> = ({
	min = 0,
	max = 100,
	step = 1,
	value = 1,
	onChange,
	type = 'default',
}) => {
	const [count, setCount] = useState<number>(value);

	const handleDecrease = () => {
		if (count > min) {
			const newValue = count - step;
			setCount(newValue);
			onChange?.(newValue);
		}
	};

	const handleIncrease = () => {
		if (count < max) {
			const newValue = count + step;
			setCount(newValue);
			onChange?.(newValue);
		}
	};

	return (
		<div className={`${cl.stepper} ${cl[type] || ''}`}>
			<button
				className={cl.button}
				onClick={handleDecrease}
				disabled={count <= min}
			>
				<img src="/image/-.svg" alt="" />
			</button>
			<p className={cl.value}>
				{count}
				{type === 'doubleTickets' ? <span>{`/${max}`}</span> : ''}
			</p>
			<button
				className={cl.button}
				onClick={handleIncrease}
				disabled={count >= max}
			>
				<img src="/image/+.svg" alt="" />
			</button>
		</div>
	);
};

export default Stepper;
