import { parse } from 'date-fns';
import { format } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ISliderBannerProps } from './slider-banner.model';
import { Button } from '../ui/buttons/button/button.component';
import { ArrowRightIcon } from '../ui/icons';
import { dateFnsParse } from '../../utils/date.utils';
import cl from './slider-banner.module.scss';
import 'swiper/css/pagination';

const BREAKPOINTS = {
	0: {
		slidesPerView: 1.1,
		spaceBetween: 10,
	},
	540: {
		slidesPerView: 1.5,
		spaceBetween: 10,
	},
	680: {
		slidesPerView: 1.8,
		spaceBetween: 10,
	},
	768: {
		slidesPerView: 1,
		spaceBetween: 0,
	},
};

const pagination = {
	el: '.swiper-banner-pagination',
	clickable: true,
	renderBullet: function (index: number, className: string) {
		return `<span class="dot swiper-pagination-bullet"></span>`;
	},
};

export const SliderBanner = ({
	slides,
	className = '',
	onAction,
}: ISliderBannerProps) => {
	const { i18n, t } = useTranslation();

	return (
		<div className={cl.container + ' ' + className}>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__prev +
					' swiper-banner-button-prev ' +
					(slides.length <= 1 ? cl.navigation__btn__hide : '')
				}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__next +
					' swiper-banner-button-next ' +
					(slides.length <= 1 ? cl.navigation__btn__hide : '')
				}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<Swiper
				breakpoints={BREAKPOINTS}
				slidesPerView={1}
				spaceBetween={0}
				className={'swiper-banner ' + cl.swiper}
				modules={[Navigation, Pagination]}
				pagination={pagination}
				navigation={{
					nextEl: '.swiper-banner-button-next',
					prevEl: '.swiper-banner-button-prev',
					disabledClass: 'swiper-button-disabled',
				}}
			>
				{slides.map((item, index) => {
					const dateParse = item.date?.split(' ');
					const dataParseDate =
						dateParse && dateParse[0] ? dateParse[0]?.split('.') : null;
					const { day, mount_label } = dateFnsParse(
						item.date && dateParse && dataParseDate
							? [
									[dataParseDate[2], dataParseDate[1], dataParseDate[0]].join(
										'.',
									),
									dateParse[1],
								].join(' ')
							: null,
						i18n.language,
					);

					const time = item?.date ? (() => {
						const parsedDate = parse(item.date, 'dd.MM.yyyy HH:mm:ss', new Date());
						return format(parsedDate, 'HH:mm');
					})() : null;

					return (
						<SwiperSlide key={index}>
							<div className={cl.slide}>
								<img
									src={item.image}
									alt=""
									loading="lazy"
									decoding="async"
									className={cl.bg}
								/>
								<div className={cl.info}>
									{item.subtitle && (
										<div className={cl.subtitle}>{item.subtitle}</div>
									)}
									<h2 className={cl.title}>{item.title}</h2>
									<p className={cl.desc}>{item.description}</p>
									<div
										className={
											(item.country && item.country !== '') || item.date
												? cl.header
												: ''
										}
									>
										<div className={cl.header__info}>
											{item.date && (
												<time className={cl.time} dateTime={item.date}>
													{day} {mount_label}, {time}
												</time>
											)}
											{item.country && item.date && (
												<svg
													className={cl.sp}
													xmlns="http://www.w3.org/2000/svg"
													width="8"
													height="8"
													viewBox="0 0 3 4"
													fill="none"
												>
													<circle cx="1.5" cy="2" r="1.5" fill="#EDDBC7" />
												</svg>
											)}
											{item.country && (
												<address className={cl.address}>{item.country}</address>
											)}
										</div>

										{onAction && (
											<Button className={cl.btn} theme="white">
												{t('btn.signup')}
											</Button>
										)}
									</div>
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
			<div className={'swiper-banner-pagination ' + cl.pagination}></div>
		</div>
	);
};
