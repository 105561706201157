import { useEffect, useMemo, useState } from 'react';
import { IScheduleItemData } from '../../../@types';
import SuccessModalContentComponent from '../../modals-content/success-modal-content/success-modal-content.component';
import TicketModalCancellationContent from '../../modals-content/ticket-modal-cancellation-content/ticket-modal-cancellation-content.component';
import Modal from '../../ui/modal/modal.component';
import { CloseModalIcon } from '../../ui/icons';
import { apiServices } from '../../../services/api';
import cl from './modal-ticket-cancellation.module.scss';

interface IModalTicketCancellationProps {
	isOpen: boolean;
	closeModal: () => void;
	dataRecord: IScheduleItemData | null;
	updateRecords: () => void;
}

const ModalTicketCancellation = ({
	isOpen,
	closeModal,
	dataRecord,
	updateRecords,
}: IModalTicketCancellationProps) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [selectedCount, setSelectedCount] = useState(1);

	const handleUnsubscribeTickets = () => {
		if (!dataRecord) return
		apiServices
			.scheduleUnsubscribe({ schedule: dataRecord.id, count: selectedCount })
			.then(() => {
				updateRecords();
				setCurrentStep((prev) => prev + 1);
			});
	};

	const generateDescriptionDate = useMemo(() => {
		return {
			selectedCount: selectedCount || 1,
			purchasedCount: dataRecord?.user_bookings_count || 1,
		};
	}, [selectedCount, dataRecord?.user_bookings_count]);

	useEffect(()=> {
		if (!isOpen) {
			setCurrentStep(1);
		}
	}, [isOpen])

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				closeModal();
			}}
			animate="fade"
			classes={{
				content: `${cl.content} ${currentStep === 2 ? cl.readyContent : ''}`,
			}}
			closeIcon={<CloseModalIcon />}
			portal
		>
			<div className={cl.container}>
				{currentStep === 1 && (
					<TicketModalCancellationContent
						handleUnsubscribeTickets={handleUnsubscribeTickets}
						handleUpdateCount={setSelectedCount}
						purchasedCount={dataRecord?.user_bookings_count}
					/>
				)}
				{currentStep === 2 && (
					<div className={cl.successModalWrapper}>
						<SuccessModalContentComponent
							type={'unsubscribeReady'}
							handleClose={closeModal}
							descriptionData={generateDescriptionDate}
						/>
					</div>
				)}
			</div>
		</Modal>
	);
};
export default ModalTicketCancellation;
